import React from 'react'
import { useIntl } from 'react-intl'

import { Loader } from 'components'

import DeleteIcon from 'assets/icons/DeleteIcon'
import TicketIcon from 'assets/icons/TicketIcon'
import { STicketSummary } from './TicketSummary.styled'
import { defaultTimezone } from 'const/defaultTimezone'

function TicketSummary({
  ticket,
  price,
  amount,
  onRemove,
  withoutVatInformation,

  simple,

  ...res
}) {
  const { locale, formatMessage, formatDate } = useIntl()

  return (
    <STicketSummary {...res}>
      <div className="icon">
        <TicketIcon />
      </div>
      <div className="content">
        <p className="name">
          {formatMessage({ id: 'tickets.ticketDetailsType' })} - Code Europe
          2025
        </p>
        <p className="date-and-place">
          {formatDate(
            ticket.date_start.replace(/-/g, '/'),
            {
              day: 'numeric',
              month: '2-digit',
            },
            {
              timeZone: defaultTimezone,
            }
          )
            .split('/')
            .reverse()
            .join('.')}{' '}
          -{' '}
          {formatDate(
            ticket.date_end.replace(/-/g, '/'),
            {
              day: 'numeric',
              month: '2-digit',
            },
            {
              timeZone: defaultTimezone,
            }
          )
            .split('/')
            .reverse()
            .join('.')}{' '}
          | {locale === 'pl' ? ticket.place : ticket.place_en}
        </p>
        {!simple ? (
          <>
            <p className="count">
              {formatMessage({ id: 'tickets.ticketAmount' })}: {amount}
            </p>
            <p className="price">
              {formatMessage({ id: 'tickets.orderPrice' })}:{' '}
              <span>
                <strong>{price.priceAfterDiscount} zł</strong>{' '}
                {!withoutVatInformation
                  ? `(${formatMessage({
                      id: 'tickets.ticketPriceIncludingVat',
                    })})`
                  : null}
              </span>
            </p>{' '}
          </>
        ) : null}
      </div>
      {onRemove ? (
        <div className="actions">
          {price.loading ? (
            <Loader className="price-loader" size={3} />
          ) : (
            <button type="button" className="remove" onClick={onRemove}>
              <DeleteIcon />
            </button>
          )}
        </div>
      ) : null}
    </STicketSummary>
  )
}

export default TicketSummary
